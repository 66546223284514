.faq_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    min-height: 84px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    cursor: pointer;
}

.faq_text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.quastion {
    width: 100%;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
}

.answer {
    color: #8C8C8C;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 1;
    max-height: 400px;
    transition: all .5s ease;
}

.faq_text .hidden {
    opacity: 0;
    position: absolute;
    top:0px;
    max-width: 0px;
    max-height: 0px;
}


.cross {
    display: inherit;
    width: 64px;
    min-height: 100%;
    align-self: flex-start;
    transition: transform .5s ease;
}

.cross:hover{
    opacity: 0.85;
}

.vertical {
    rotate: 90deg;
}

.horizontal {
    position: relative;
    right: 64px;
}

.cross-open{
    transform: rotate(-90deg);
}

.cross-open .horizontal{
    transform: scale(0);
}

@media screen and (max-width: 1023px) {
    .cross{
        align-self: center;
    }    

    .faq_block{
        width: 390px;
    }
    .quastion {
        line-height: 140%;
    }
}