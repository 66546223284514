@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Manrope:200,300,regular,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    width: 100vw;

    margin-bottom: 160px;
    margin-top: 35vh;
    padding: 0px 10px;

    font-family: Raleway;
    color: white;

    gap: 35vh;

}

.block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 1004px;
    max-width: 1360px;
    width: 100%;

    scroll-snap-align: start;

}

.title {
    font-size: 64px;
    margin-bottom: 20px;
    font-weight: 700;
}

.photo {
    width: 445px;
    height: 571px;
    border-radius: 40px;
}

.company_info {
    display: flex;
    flex-direction: row;
    gap: 72px;
}

.info {
    display: flex;
    flex-direction: column;

    width: 843px;
    height: 447px;

    gap: 40px;
}

.experience_title {
    font-size: 20px;
    font-family: Manrope;
}

.experience {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.experience .text_big {
    font-family: Manrope;
}

.ceo {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.ceo svg {
    align-self: center;

    width: 44px;
    height: 44px;
}

.services {
    align-items: start;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;

    width: 298px;
    height: 466px;

    gap: 10px;
    padding: 24px 24px;
}

.card img {
    width: 250px;
    height: 250px;
}

.card:hover {
    border-radius: 12px;
    backdrop-filter: blur(4.5px);
    background: linear-gradient(rgba(122, 120, 232, 0), rgba(122, 120, 232, 0.08), rgba(122, 120, 232, 0.15), rgba(122, 120, 232, 0.08));
}

.title_text {
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
    opacity: 0.5;
}

.tag {
    display: flex;

    width: max-content;

    padding: 8px 16px;

    border-radius: 8px;
    background: rgba(255, 255, 255, 0.10);

    text-shadow: 0px 0px 41px #FFF;
    font-size: 16px;
    font-weight: 600;
}

.systems {
    display: flex;

    width: 100%;

    gap: 32px;
}

.systems .card {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: max-content;

    padding: 40px;
    border-radius: 16px;
    gap: 40px;

    background: linear-gradient(0deg, #0A0A0A26, #70707026);
    backdrop-filter: blur(3px);
}

.systems .card span {
    font-size: 32px;
    font-weight: 500;
}

.systems .card ul {
    display: flex;
    flex-direction: column;

    list-style-type: none;

    gap: 16px;
}

.systems .card li {
    background: url("../../img/svg/circle.svg") no-repeat;
    background-position: 0 0;
    padding-left: 40px;

    color: #C0C0C0;

    font-size: 20px;
    line-height: 28px;
    gap: 50px;
}

.systems .card button{
    width: 100%;
}

.our_system{
    display: flex;
    flex-direction: column;

    gap: 32px;
}

.staps_list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 74px;

    padding: 16px 32px;

    border-radius: 16px;

    background: linear-gradient(-45deg, #0A0A0A33, #70707033);
    backdrop-filter: blur(8px);
}

.stap {
    font-size: 24px;
    line-height: 42px;
    font-weight: 500;
}

.stap img {
    height: 24px;
    width: 24px;
}

#cicd {
    position: absolute;
    width: 100%;
    opacity: .1;
}

.automatisation {
    gap: 29px;
}

.automatisation .list {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 429px));

    width: 100%;
    gap: 36px;

    flex-wrap: wrap;
}

.automatisation .card {
    justify-content: space-around;

    height: 258px;
    max-width: 416px;
    width: 100%;

    padding: 20px;
    border-radius: 16px;

    background: linear-gradient(-45deg, #0A0A0A33, #70707033);
    backdrop-filter: blur(6px);
}

.automatisation .card img {
    width: 44px;
    height: 44px;
    
    mix-blend-mode: luminosity;
}

.automatisation .card button {
    width: 100%;
}

.automatisation .ai {
    position: relative;
    z-index: 1;
    
    width: 100%;
    height: 207px;

    background-image: url("../../img/automatisation/AiWoman.png");
    background-size: cover;
    background-position: center;
}

.stack {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    gap: 44px;
}

.stack button {
    margin-top: 24px;
}

.stack .left {
    display: flex;
    flex-direction: column;

    gap: 24px;
}

.stack .right {
    display: flex;
    flex-direction: row;

    width: 464px;
    height: 526px;

    gap: 24px;
}

.stack .left_collumn,
.stack .right_collumn {
    display: flex;
    flex-direction: column;

    width: 220px;
    height: 100%;

    gap: 32px;
}

.stack .card {
    flex-direction: row;
    justify-content: space-between;

    height: -webkit-fill-available;
    width: 100%;

    padding: 16px;
    border-radius: 16px;

    background: linear-gradient(-45deg, #0A0A0A33, #70707033);
    backdrop-filter: blur(3px);
}

.stack .card .text {
    align-self: flex-end;
}

.stack .card img {
    width: 64px;
    height: 64px;
}


.work_stages {
    padding: 160px 10px;
}

.stage {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 35px;
}

.stage .text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stages {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    width: 100%;

    margin-top: 45px;
}

.portfolio_projects {
    width: 100vw;
    margin-top: 80px;
}

.swiper-wrapper {
    display: flex;
    align-content: start;

    gap: 32px;

    animation: scroll 10s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-2160px);
    }
}

.portfolio {
    width: 1920px;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    width: 400px;
    height: 189px;

    padding: 20px;
    border-radius: 12px;

    font-family: Montserrat;

    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(25px);
}

.project:hover {
    background: linear-gradient(77deg, rgba(60, 138, 255, 0.20) -0.04%, rgba(60, 138, 255, 0.00) 99.96%), rgba(255, 255, 255, 0.05);
}

.project_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name {
    margin-left: 16px;
    font-size: 36px;
}

.project_img img {
    border-radius: 9px;
}

.project_description {
    font-size: 16px;
    line-height: 22.4px;
}

.project_info {
    display: flex;
    flex-direction: row;

    height: 100%;

    margin-top: 10px;

    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.project_text {
    display: flex;
    flex-direction: column;

    gap: 5px;
}

.project_info svg {
    margin-right: 10px;
}

.avatar {
    width: 200px;
    height: 200px;

    margin-bottom: 10px;

    background-size: 100%;
}

.avatar img {
    width: 100%;
    height: 100%;

    z-index: 0;
}

.team {
    width: 100%;

    padding: 86px 0px;
}

.team_people {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    width: 100%;

    margin-top: 100px;
}

.human {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    font-style: normal;
    line-height: 22.4px;
}

.inputs {
    flex-wrap: wrap;

    width: 100%;

    padding: 40px;
    margin-top: 80px;
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(25px);
}

#contactForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 20px;
}

.inputs input {
    align-items: flex-start;

    width: 290px;
    height: 62px;

    padding: 20px;
    border-radius: 10px;
    border: 2px solid rgba(251, 251, 255, 0.50);

    color: white;

    background-color: #00000000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.feedback {
    position: relative;
    padding: 160px 10px;
}

.feedback img {
    position: absolute;
    z-index: -1;
}

#feedback_img1 {
    left: 0px;
    transform: translateX(-40%);
}

#feedback_img2 {
    right: 0px;
    transform: translateX(40%);
}

.white_button {
    width: 290px;

    color: #003B94;
    border-radius: 10px;

    font-size: 18px;
    background: #FFF;
    backdrop-filter: blur(25px);

    transition: all 0.8s ease;
}

.white_button:hover {
    color: white;
    border-radius: 16px;

    background: #3C8AFF;
    box-shadow: 0px 0px 27.5px 0px #3C8AFF;
}

.blue_button:hover {
    color: #3C8AFF;
    border-radius: 16px;

    background: white;
    box-shadow: 0px 0px 27.5px 0px #3C8AFF;
}

.quastions {
    margin-top: 40px;
    width: 100%;
}

@media screen and (width > 1024px) {
    .stage:not(:first-child) {
        margin-left: 60px;
    }

    .human:not(:first-child) {
        margin-left: 80px;
    }

    .stage:not(:first-child) {
        margin-left: 80px;
    }
}

@media screen and (width < 1340px) {
    .cards {
        justify-content: space-evenly;
    }

    .systems {
        height: 445px;
        padding: 0 15px;
    }

    .inputs input {
        width: 30%;
        margin-bottom: 20px;
    }

    form .white_button {
        width: 100%;
    }
}










@media screen and (max-width: 1023px) {
    .content {
        align-self: center;
        
        max-width: 370px;

        padding: 0px 10px;
        margin: 0px;
        margin-bottom: 160px;

        gap: 20vh;
    }

    .block {
        min-width: 100%;
    }

    .company_info {
        position: relative;

        flex-direction: column;

        width: 100%;

        margin-top: 0px;
        gap: 40px;
    }

    .company_info .photo {
        width: 100%;
        height: auto;
    }

    .photo img {
        width: 100%;
    }

    .company_info .info {
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .info .experience {
        flex-direction: column;
    }

    .ceo {
        width: 370px;
    }

    .ceo p {
        display: flex;
        width: 315px;
        align-self: end;
    }

    .cards {
        flex-direction: column;
        align-items: center;

        margin-top: 40px;

        gap: 32px;
    }

    .card {
        align-items: center;

        width: 100%;
        height: auto;

        padding: 24px 12px;
    }

    .card_info {
        width: 100%;
    }

    .systems {
        flex-direction: column;

        height: 1100px;

        padding: 0;
    }

    .staps{
        height: 545px;
    }

    .staps>img {
        display: none;
    }

    .staps_list{
        display: none;
    }

    .staps_list img{
        rotate: 90deg;
    }

    .automatisation .list{
        display: flex;
        flex-direction: column;
    }

    .automatisation .card {
        align-items: flex-start;

        width: 100%;
    }

    .automatisation .ai{
        height: 50vw;
    }

    .stack {
        flex-direction: column;
    }

    .stack button {
        width: 100%;
    }

    .stack .right {
        flex-direction: column;

        width: 100%;
        height: auto;
    }

    .stack .left_collumn,
    .stack .right_collumn {
        width: 100%;
    }

    .work_stages {
        padding: 0px;
    }

    .stages {
        flex-direction: column;
        width: 100%;
        gap: 80px;
    }

    .portfolio {
        width: 100%;
    }

    .portfolio_projects {
        flex-direction: column;
        gap: 10px;
    }

    .team {
        padding: 0px;
    }

    .team_people {
        flex-direction: column;
        width: 100%;
        gap: 90px;
    }

    .feedback {
        height: auto;

        padding: 0px;
    }

    .inputs {
        width: 100%;
        height: 100%;
    }

    #feedback_img1 {
        display: none;
    }

    #feedback_img2 {
        right: -120px;
        top: -140px;
    }

    #contactForm {
        flex-direction: column;
        align-items: center;
    }


    .inputs input {
        width: 100%;
    }

    #white_button {
        width: 100%;
    }

    .quastions {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100vw;
    }

    .work_stages .text {
        transform: scale(1.3);
    }

    .title {
        width: 100%;

        font-size: 44px;
    }

    .title_text {
        display: flex;
        flex-wrap: wrap;

        width: 100%;

        font-size: 22px;
    }
}