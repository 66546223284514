@import url(https://fonts.googleapis.com/css?family=Manrope:200,300,regular,500,600,700,800);

.header_external {
    display: flex;
    width: 100%;
    height: 90px;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #00000033;
    backdrop-filter: blur(25px);
    color: white;
    justify-content: center;
    z-index: 100;
}

.header_internal {
    width: 1360px;
    height: 50px;
    margin: 20px 280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
}

.logo {
    font-size: 33.626px;
    font-style: normal;
    font-weight: 800;
}

.logo .blue {
    color: #004DFF;
}

.header_internal ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header_internal li {
    display: block;
    padding: 8.5px 16px;
    color: white;
}

.header_internal li:hover{
    color: #3C8AFF;
    border-radius: 5px;
}

.email_support {
    font-weight: 700;
}

.language,
.media_links {
    max-width: 85px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}


.language{
    width: 85px;
    height: 40px;
}


/* Изменение цвета обводки path */
.media_links svg:hover path {
    stroke-width: 1;
    fill: white;
}

.language span {
    margin-top: 0px;
}

@media screen and (max-width: 1023px) {
    .header_external{
        width: 100vw;
        padding: 10px 20px;
        align-items: center;
    }
    .header_internal {
        justify-content: start;
        width: 370px;
        margin: 0px;
    }

    .nav_bar,.email_support,.language {
        display: none;
    }

    .media_links:last-child{
       display: none;
    }

}