.lights {
    width: 100vw;
    position: absolute;
    z-index: -1;
}

.ellipse {

    position: absolute;
    opacity: 0.5;
    background: #004DFF;
    filter: blur(500px);
    z-index: -1;

}

.e3 {
    width: 958px;
    height: 958px;
    right: calc(50vw - 960px - 479px);
    top: 400px;
}

.e25 {
    width: 1066px;
    height: 1066px;
    top: 3625px;
    right: calc(50vw - 960px - 532px);
}

.e4 {
    width: 1066px;
    height: 1066px;
    top: 2550px;
    left: calc(50vw - 960px - 533px);
}

.e_preview {
    width: 785.918px;
    height: 345.57px;
    position: absolute;
    left: 35vw;
    top: 450px;
    background-color: #004DFF;
    opacity: 1;
}

@media screen and (max-width: 1023px) {

    .lights {
        height: 10000px;
        overflow: hidden;
    }

    .e25 {
        display: none;
    }


    .e_preview {
        width: 134.089px;
        height: 629.066px;
        transform: rotate(-134.113deg), translateX(-208px);
        border-radius: 629.066px;
        filter: blur(150px);
        top: 236px;
        left: 0px;
    }

    .e4 {
        width: 785.918px;
        height: 345.57px;
        top: 236px;
        left: 0px;
        filter: blur(150px);
        transform: rotate(-134.113deg), translateX(-208px);
    }

    .e3 {
        width: 958px;
        height: 958px;
        top: 5482px;
        right: 0px;
        transform: translateX(50%);
    }
}