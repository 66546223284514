*,*::before,*::after{
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
}
html,body{
    min-height: 100%;
    min-width: 100vw;
}
body{
    background: #00000F;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

::-webkit-scrollbar {
    width: 5px;
    height: 50px;
    background-color: rgba(255, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background-color: #eaeaea;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    position: absolute;
    z-index: -2;
}

#root{
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.start {
    align-self: start;
}

p, 
span{
    margin: 0px 0px;
    cursor: default;
}
button {
    padding: 16px;
    justify-content: center;
    align-items: center;
    font-family: Raleway;
    line-height: 22px;
    font-weight: 700;
    border: 0px;
}
button, a{
    cursor: pointer;
    text-decoration: none;
}
a, a:active{
    color: white;
}

.footer_external, .content{
    z-index: 1;
}

@media screen and (max-width: 1023px) {
   html {
    width: 100vw;
    overflow-x: hidden;
   }
    
}